export enum GasTankBackgroundColor {
  BROWN = "#D58C73",
  PURPLE = "#B18BDB",
  GREEN = "#689FA3",
}

export interface DetailsPerScoreLevel {
  title: string;
  description: string;
  backgroundColor: GasTankBackgroundColor;
}

interface Props {
  width: number;
  height: number;
  percentage: number;
  detailsPerScoreLevel: DetailsPerScoreLevel;
  isMobile: boolean;
}

function polarToCartesian(
  centerX: number,
  centerY: number,
  radiusX: number,
  radiusY: number,
  angleInDegrees: number,
) {
  const angleInRadians = ((angleInDegrees - 90) * Math.PI) / 180.0;
  return {
    x: centerX + radiusX * Math.cos(angleInRadians),
    y: centerY + radiusY * Math.sin(angleInRadians),
  };
}

function calculateRadiusX(width: number, isMobile: boolean): number {
  return width / (isMobile ? 1.44 : 2.38); // Adjusted calculation
}

function calculateRadiusY(height: number, isMobile: boolean): number {
  return height / (isMobile ? 1.4 : 2.42); // Adjusted calculation
}

function calculateCenterX(width: number, isMobile: boolean): number {
  return width / (isMobile ? 1.23 : 2); // Adjusted calculation
}

function calculateCenterY(height: number, isMobile: boolean): number {
  return height - height / (isMobile ? 42 : 2.5); // Adjusted calculation
}

export default function GasTankShape({
  width,
  height,
  percentage,
  isMobile,
  detailsPerScoreLevel,
}: Props) {
  const strokeWidth = 22;
  const radiusX = calculateRadiusX(width, isMobile);
  const radiusY = calculateRadiusY(height, isMobile);
  const centerX = calculateCenterX(width, isMobile);
  const centerY = calculateCenterY(height, isMobile);
  const totalAngle = 180; // Total angle of the arc
  const startAngle = -90; // Start angle of the arc
  const endAngle = startAngle + (percentage / 100) * totalAngle;

  const start = polarToCartesian(centerX, centerY, radiusX, radiusY, endAngle);
  const end = polarToCartesian(centerX, centerY, radiusX, radiusY, startAngle);
  const largeArcFlag = percentage > 50 ? 1 : 0;

  // Path description for the arc
  const arcPath = `
    M ${start.x} ${start.y}
    A ${radiusX} ${radiusY} 0 ${largeArcFlag} 0 ${end.x} ${end.y}
    L ${centerX} ${centerY}
    Z
`;

  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 520 340"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g filter="url(#filter0_bd_1_4)">
        <path
          d="M260 5.66476C80.7792 5.66476 -64.5078 150.952 -64.5078 330.172H584.508C584.508 150.952 439.221 5.66476 260 5.66476Z"
          fill="url(#paint0_radial_1_4)"
        />
        <path
          d="M584.007 329.672H-64.0074C-63.7375 150.958 81.2221 6.16476 260 6.16476C438.778 6.16476 583.737 150.958 584.007 329.672Z"
          stroke="url(#paint1_radial_1_4)"
        />
      </g>
      <mask
        id="mask0_1_4"
        style={{
          maskType: "alpha",
        }}
        maskUnits="userSpaceOnUse"
        x={-65}
        y={5}
        width={650}
        height={276}
      >
        <rect
          x={-64.5078}
          y={5.66476}
          width={649.016}
          height={274.73}
          fill="#D9D9D9"
        />
      </mask>
      <g mask="url(#mask0_1_4)">
        <g opacity={0.7} filter="url(#filter1_f_1_4)">
          <circle
            cx={260}
            cy={330.172}
            r={193.129}
            fill="url(#greenBackground)"
          />
        </g>
      </g>
      {/* Background progress bar lines*/}
      <defs>
        <mask id="progressMask">
          <path d={arcPath} fill="white" />
        </mask>
      </defs>
      <path
        d="M136.36 114.695C102.101 134.414 72.9886 162.069 51.5312 195.149"
        stroke="url(#paint3_linear_1_4)"
        strokeOpacity={0.2}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
      />
      <path
        d="M176.348 96.2033C202.521 86.8309 230.725 81.7256 260.121 81.7256C289.517 81.7256 317.721 86.8309 343.894 96.2033"
        stroke="url(#paint6_linear_1288_169430)"
        strokeOpacity="0.2"
        strokeWidth={strokeWidth}
        strokeLinecap="round"
      />
      <path
        d="M383.879 114.695C418.011 134.341 447.035 161.864 468.469 194.781"
        stroke="url(#paint5_linear_1_4)"
        strokeOpacity={0.2}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
      />

      {/* <circle cx={centerX} cy={centerY} r={5} fill="red" />*/}
      {/* <path d={arcPath} fill="red" />*/}
      {/* Progress bar lines fillers */}
      <g mask="url(#progressMask)">
        <path
          d="M136.36 114.695C102.192 134.362 73.1431 161.922 51.7021 194.886"
          stroke="url(#paint4_linear_1_4)"
          strokeWidth={strokeWidth}
          strokeLinecap="round"
        />
        <path
          d="M176.348 96.2033C202.521 86.8309 230.725 81.7256 260.121 81.7256C289.517 81.7256 317.721 86.8309 343.894 96.2033"
          stroke="url(#paint7_linear_1_4)"
          strokeWidth={strokeWidth}
          strokeLinecap="round"
        />
        <path
          d="M383.879 114.695C418.011 134.341 447.035 161.864 468.469 194.781"
          stroke="url(#paint6_linear_1_4)"
          strokeWidth={strokeWidth}
          strokeLinecap="round"
        />
      </g>
      <defs>
        <filter
          id="filter0_bd_1_4"
          x={-104.508}
          y={-34.3352}
          width={729.016}
          height={404.508}
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity={0} result="BackgroundImageFix" />
          <feGaussianBlur in="BackgroundImageFix" stdDeviation={20} />
          <feComposite
            in2="SourceAlpha"
            operator="in"
            result="effect1_backgroundBlur_1_4"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feMorphology
            radius={1}
            operator="erode"
            in="SourceAlpha"
            result="effect2_dropShadow_1_4"
          />
          <feOffset dy={4} />
          <feGaussianBlur stdDeviation={12} />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.2 0"
          />
          <feBlend
            mode="normal"
            in2="effect1_backgroundBlur_1_4"
            result="effect2_dropShadow_1_4"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect2_dropShadow_1_4"
            result="shape"
          />
        </filter>
        <filter
          id="filter1_f_1_4"
          x={26.8711}
          y={97.0428}
          width={466.258}
          height={466.258}
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity={0} result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feGaussianBlur
            stdDeviation={20}
            result="effect1_foregroundBlur_1_4"
          />
        </filter>
        <radialGradient
          id="paint0_radial_1_4"
          cx={0}
          cy={0}
          r={1}
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(255.176 5.66476) rotate(88.2971) scale(162.326 324.511)"
        >
          <stop stopColor="#8ACDD1" stopOpacity={0.2} />
          <stop offset={1} stopColor="#44377B" stopOpacity={0} />
        </radialGradient>
        <radialGradient
          id="paint1_radial_1_4"
          cx={0}
          cy={0}
          r={1}
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(260 11.2875) rotate(90) scale(156.631 313.262)"
        >
          <stop stopColor="#DBBAFF" />
          <stop offset={1} stopColor="#44377B" stopOpacity={0} />
        </radialGradient>
        <linearGradient
          id="greenBackground"
          x1={260}
          y1={192.306}
          x2={260}
          y2={259.905}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor={detailsPerScoreLevel.backgroundColor} />
          <stop offset={1} stopColor="#3A2E6C" stopOpacity={0} />
        </linearGradient>
        <linearGradient
          id="purpleBackground"
          x1={260}
          y1={192.306}
          x2={260}
          y2={259.905}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#B18BDB" />
          <stop offset="1" stopColor="#3A2E6C" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_1_4"
          x1={72.1646}
          y1={165.656}
          x2={37.7068}
          y2={249.969}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" />
          <stop offset={1} stopColor="#504283" stopOpacity={0} />
        </linearGradient>
        <linearGradient
          id="paint4_linear_1_4"
          x1={145.846}
          y1={107.598}
          x2={3.89608}
          y2={180.194}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#936CD2" />
          <stop offset={1} stopColor="#FFB9A5" />
        </linearGradient>
        <linearGradient
          id="paint5_linear_1_4"
          x1={432.517}
          y1={148.444}
          x2={509.082}
          y2={225.554}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" />
          <stop offset={1} stopColor="white" />
        </linearGradient>
        <linearGradient
          id="paint6_linear_1_4"
          x1={378.042}
          y1={112.493}
          x2={481.155}
          y2={227.075}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#A9D798" />
          <stop offset={1} stopColor="#32A3AA" />
        </linearGradient>
        <linearGradient
          id="paint7_linear_1_4"
          x1={176.348}
          y1={88.9644}
          x2={366.335}
          y2={96.2033}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#936CD2" />
          <stop offset={1} stopColor="#A9D798" />
        </linearGradient>
        <linearGradient
          id="paint6_linear_1288_169430"
          x1={272.685}
          y1={87.8266}
          x2={275.173}
          y2={115.284}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" />
          <stop offset="1" stopColor="white" />
        </linearGradient>
      </defs>
    </svg>
  );
}
